/*-
 * #%L
 * Fishola :: Mobile
 * %%
 * Copyright (C) 2019 - 2021 INRAE - UMR CARRTEL
 * %%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 * #L%
 */
import TripMeta from "@/pojos/TripMeta";
import TripSpecies from "@/pojos/TripSpecies";
import TripMain from "@/pojos/TripMain";
import {
  TripLight,
  TripMode,
  TripBean,
  CatchBean,
  DeviceType,
  TripSocialReaction,
  SocialReaction,
} from "@/pojos/BackendPojos";
import CatchSummary from "@/pojos/CatchSummary";

import Helpers from "@/services/Helpers";
import Constants from "@/services/Constants";

import AbstractFisholaService from "@/services/AbstractFisholaService";
import PicturesService from "@/services/PicturesService";
import ReferentialService from "@/services/ReferentialService";
import ProfileService from "@/services/ProfileService";
import GeolocationService from "@/services/GeolocationService";

import moment from "moment";

export class TripsAndCount {
  offlineMarker: boolean = false;
  constructor(public trips: TripLight[], public count: number) {}
}

export default class TripsService extends AbstractFisholaService {

  static instance?: TripsService;

  constructor() {
    super();
  }

  static getInstance(): TripsService {
    if (!this.instance) {
      console.debug("Pas encore d'instance partagée, on la créé");
      this.instance = new TripsService();
    }
    return this.instance;
  }

  static newTrip(mode: TripMode): Promise<string> {
    const newTrip: TripMeta = {
      id: Constants.NEW_TRIP_ID,
      mode: mode,
    };

    if (mode == "Live") {
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };
      const now = new Date();

      newTrip.name = "Sortie du " + now.toLocaleDateString("fr-FR", options);
      newTrip.date = now;
      newTrip.startedAt = moment().format(moment.HTML5_FMT.TIME_SECONDS);
    }

    return new Promise<string>((resolve, reject) => {
      this.getDatabase()
        .onCreationTrip.put(newTrip)
        .then((id) => resolve(id), reject);
    });
  }

  static newLiveTrip(): Promise<string> {
    return TripsService.newTrip("Live");
  }

  static newAfterwardsTrip(): Promise<string> {
    return TripsService.newTrip("Afterwards");
  }

  static getTrip(id: any, callback: (trip: any) => any) {
    if (id == Constants.NEW_TRIP_ID || id == Constants.RUNNING_ID) {
      this.getDatabase()
        .onCreationTrip.get(id)
        .then((aaa) => {
          if (!aaa.speciesIds) {
            aaa.speciesIds = [];
          }
          callback(aaa);
        });
    } else {
      this.getDatabase()
        .dirtyTrips.get(id)
        .then((aaa) => {
          if (aaa) {
            console.debug("Sortie trouvée dans les dirtyTrips :", aaa);
            callback(aaa);
          } else {
            // Il faut charger le trip depuis le back
            const url: string = `/v1/trips/${id}`;
            this.backendGet(url).then((bbb: any) => {
              console.debug("Sortie récupérée depuis le back :", bbb);
              callback(TripsService.backendTripToTrip(bbb));
            });
          }
        });
    }
  }

  static backendCatchToCatchBean(
    realDate: moment.Moment,
    input: any
  ): CatchBean {
    const result: any = {
      tripId: input.tripId,
      id: input.id,
      speciesId: input.speciesId,
      size: input.size,
      weight: input.weight,
      keep: input.keep,
      releasedStateId: input.releasedStateId,
      techniqueId: input.techniqueId,
      description: input.description,
      sampleId: input.sampleId,
      hasPicture: input.hasPicture,
      latitude: input.latitude,
      longitude: input.longitude,
      caughtAt: input.caughtAt,
      automaticMeasure: input.automaticMeasure,
      pictureOrders: input.pictureOrders,
      hasMeasurementPicture: input.hasMeasurementPicture,
    };

    return result;
  }

  static backendTripToTrip(input: any): TripBean {
    const realDate = Helpers.parseLocalDate(input.date);
    const realCreatedOn = Helpers.parseLocalDateTime(input.createdOn);

    const catchs: CatchBean[] = [];
    if (input.catchs) {
      input.catchs.forEach((aCatch: any) => {
        const aCatchBean: CatchBean = TripsService.backendCatchToCatchBean(
          moment(realDate),
          aCatch
        );
        catchs.push(aCatchBean);
      });
    }

    const result: any = {
      id: input.id,
      createdOn: realCreatedOn,
      mode: input.mode,
      type: input.type,
      name: input.name,
      lakeId: input.lakeId,
      weatherId: input.weatherId,
      date: realDate,
      speciesIds: input.speciesIds || [],
      techniqueIds: input.techniqueIds || [],
      catchs: catchs,
      startedAt: input.startedAt,
      finishedAt: input.finishedAt,
    };

    if (input.modifiableUntil) {
      result.modifiableUntil = Helpers.parseLocalDateTime(
        input.modifiableUntil
      );
    }
    return result;
  }

  static storedTripToLight(input: TripBean): TripLight {
    const seconds: number = Helpers.computeDurationInSeconds(
      input.startedAt,
      input.finishedAt
    );
    const catchsCount: number = input.catchs ? input.catchs.length : 0;

    const result: TripLight = <any>input;
    result.modifiable = true;
    result.durationInSeconds = seconds;
    result.catchsCount = catchsCount;

    return result;
  }

  static backendTripToLight(input: any): TripLight {
    const realDate = Helpers.parseLocalDate(input.date);
    input.date = realDate;
    return input;
  }

  static async listSocialTrips(lakeId: string) {
    const params = {
      lakeId: lakeId,
      pageNumber: 1,
      pageSize: 40
    };
    const result = await this.backendGetWithArgs("/v1/social/", params);
    return result.elements;
  }

  static async postSocialReaction(tripId: string, socialReaction: SocialReaction) {
    const tripSocialReaction: TripSocialReaction = {
      tripId: tripId,
      userId: "",
      message: "",
      reaction: socialReaction,
    };
    await this.backendPost("/v1/social/" + tripId, tripSocialReaction);
  }

  static async deleteSocialReaction(tripId: string, socialReaction: SocialReaction) {
    const tripSocialReaction: TripSocialReaction = {
      tripId: tripId,
      userId: "",
      message: "",
      reaction: socialReaction,
    };
    await this.backendDelete("/v1/social/" + tripId, tripSocialReaction);
  }

  static listTrips(
    sortDown: boolean,
    searchTerm: string,
    rawPageIndex?: number
  ): Promise<TripsAndCount> {
    const pageIndex = rawPageIndex || 0;

    return new Promise<TripsAndCount>((resolve, reject) => {
      const result: TripLight[] = [];

      const dirtyTripsIds: string[] = [];

      this.getDatabase().dirtyTrips.toArray((trips) => {
        trips.forEach((trip) => {
          const tripLight: TripLight = TripsService.storedTripToLight(trip);
          dirtyTripsIds.push(tripLight.id);
          if (pageIndex === 0) {
            result.push(tripLight);
          }
        });
      });

      const page = {
        pageNumber: pageIndex,
        pageSize: 10,
        desc: sortDown,
        term: searchTerm,
      };

      const promise = this.backendGetWithArgs("/v1/trips", page);
      this.timeout(5000, promise).then(
        (trips: any) => {
          console.debug("Sorties récupérées depuis le back :", trips);
          trips.elements.forEach((trip: TripLight) => {
            if (dirtyTripsIds.indexOf(trip.id) == -1) {
              const tl: TripLight = TripsService.backendTripToLight(trip);
              result.push(tl);
            }
          });
          const count: number = dirtyTripsIds.length + trips.count;
          const tripsAndCount = new TripsAndCount(result, count);
          resolve(tripsAndCount);
        },
        (error) => {
          if (error && error.timeoutReached) {
            console.error("Erreur pendant le chargement des sorties", error);
            const tripsAndCount = new TripsAndCount(
              result,
              dirtyTripsIds.length
            );
            tripsAndCount.offlineMarker = true;
            resolve(tripsAndCount);
          } else {
            reject(error);
          }
        }
      );
    });
  }

  static async catchMarkers() {
    const markers = await this.backendGet("/v1/trips/markers");
    return markers;
  }

  /**
   * Termine la phase d'ajout de captures sur une sortie (trip.id == Constants.RUNNING_ID)
   */
  static async finishTripCatchs(trip: TripMain): Promise<void> {
    if (trip.id == Constants.RUNNING_ID) {
      const source: DeviceType = await Helpers.getDeviceType();
      const frontendVersion = process.env.VUE_APP_PROJECT_VERSION;

      return new Promise<void>((resolve, reject) => {
        if (trip.mode == "Live") {
          trip.finishedAt = moment().format(moment.HTML5_FMT.TIME_SECONDS);
        }

        const tripBean: TripBean = <TripBean>trip;
        tripBean.source = source;
        tripBean.frontendVersion = frontendVersion;
        if (!tripBean.techniqueIds) {
          tripBean.techniqueIds = [];
        }
        if (trip.catchs) {
          trip.catchs.forEach((c) => {
            if (tripBean.techniqueIds.indexOf(c.techniqueId) == -1) {
              tripBean.techniqueIds.push(c.techniqueId);
            }
          });
        }

        this.getDatabase()
          .onCreationTrip.put(tripBean)
          .then(() => {
            resolve();
          }, reject);
      });
    } else {
      throw (
        "Ne doit être appelé que sur une sortie en cours de création. Id=" +
        trip.id
      );
    }
  }

  static saveTrip0(trip: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (trip.id == Constants.NEW_TRIP_ID || trip.id == Constants.RUNNING_ID) {
        this.getDatabase()
          .onCreationTrip.put(trip)
          .then((savedId) => {
            console.debug("Sauvegardé dans onCreationTrip", savedId);
            resolve(savedId);
          }, reject);
      } else {
        const tripBean: TripBean = <TripBean>trip;
        this.setSaveDelayMarker(tripBean);
        this.getDatabase()
          .dirtyTrips.put(tripBean)
          .then((savedId) => {
            console.debug("Sauvegardé dans dirtyTrips", savedId);
            resolve(savedId);
          }, reject);
      }
    });
  }

  static saveTripMeta(trip: TripMeta, callback: () => void) {
    this.saveTrip0(trip).then((aaa) => {
      console.debug(aaa);
      callback();
    });
  }

  static saveTripSpecies(trip: TripSpecies, callback: () => void) {
    this.saveTrip0(trip).then((aaa) => {
      console.debug(aaa);
      callback();
    });
  }

  static saveTrip(trip: TripBean, callback: () => void) {
    this.saveTrip0(trip).then((aaa) => {
      console.debug(aaa);
      callback();
    });
  }

  static deleteDirtyTrip() {
    this.getDatabase().onCreationTrip.delete(Constants.NEW_TRIP_ID);
  }

  static deleteRunningTrip() {
    this.getDatabase().onCreationTrip.delete(Constants.RUNNING_ID);
  }

  static cancelCreations() {
    this.deleteDirtyTrip();
    this.deleteRunningTrip();
  }

  /**
   * Appelé quand on a terminé l'initialisation de la sortie (meta + espèces)
   */
  static finishTripBootstrap(
    trip: TripSpecies,
    callback: (id: string) => void
  ) {
    if (trip.id == Constants.NEW_TRIP_ID) {
      trip.id = Constants.RUNNING_ID;

      const finish = () => {
        TripsService.saveTripSpecies(trip, () => {
          this.deleteDirtyTrip();
          callback(trip.id!);
        });
      };

      if (trip.mode == "Live") {
        trip.startedAt = moment().format(moment.HTML5_FMT.TIME_SECONDS);

        GeolocationService.checkWatchAndGetPositionUntilTimeout().then(
          (position) => {
            trip.beginLatitude = position.coords.latitude;
            trip.beginLongitude = position.coords.longitude;
            console.info(
              `Coordonnées de début de sortie : ${trip.beginLatitude},${trip.beginLongitude}`
            );
            finish();
          },
          (error) => {
            console.warn("Pas de coordonnées GPS", error);
            finish();
          }
        );
      } else {
        finish();
      }
    } else {
      TripsService.saveTripSpecies(trip, () => {
        callback(trip.id!);
      });
    }
  }

  static removeSaveDelayMarker(someObject: TripBean) {
    delete someObject.saveDelayMarker;
  }

  static setSaveDelayMarker(someObject: TripBean) {
    someObject.saveDelayMarker = new Date();
  }

  /**
   * Indique si la sauvegarde de la sortie doit être différée ou non
   */
  static shouldDelaySave(someObject: TripBean): boolean {
    if (!someObject.saveDelayMarker) {
      return false;
    }
    const delayMoment = moment(someObject.saveDelayMarker);
    const now = moment();
    const durationMillis = now.diff(delayMoment);
    const durationInMinutes = durationMillis / (60 * 1000);
    // On diffère les sauvegardes non explicites de moins de 30 min
    const result = durationInMinutes < 30;
    return result;
  }

  /**
   * Appelé quand la sortie est complète et qu'on veut la sauvegarder sur le serveur
   */
  static sendTrip(trip: TripBean): Promise<string> {
    if (trip.id == Constants.RUNNING_ID) {
      trip.id = "" + new Date().getTime();
    }
    this.removeSaveDelayMarker(trip);
    return new Promise<string>((resolve, _reject) => {
      this.getDatabase()
        .dirtyTrips.put(trip)
        .then((savedTripId) => {
          console.info("Nouvelle sortie dans les dirtyTrips", savedTripId);
          resolve(savedTripId);
        });
    });
  }

  static doSendTripAndCancelCreations(trip: TripBean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.sendTrip(trip).then((savedTripId) => {
        this.cancelCreations();
        resolve(savedTripId);
      }, reject);
    });
  }

  /**
   * Appelé quand la sortie est complète et qu'on veut la sauvegarder sur le serveur (trip.id == Constants.RUNNING_ID)
   */
  static sendTripAndCancelCreations(trip: TripBean): Promise<string> {
    if (trip.mode == "Live") {
      // On tente de récupérer les coordonnées GPS pour enregistrer les coordonnées de fin de sortie
      return new Promise<string>((resolve, reject) => {
        GeolocationService.checkWatchAndGetPositionUntilTimeout().then(
          (position) => {
            trip.endLatitude = position.coords.latitude;
            trip.endLongitude = position.coords.longitude;
            console.info(
              `Coordonnées de fin de sortie : ${trip.endLatitude},${trip.endLongitude}`
            );
            this.doSendTripAndCancelCreations(trip).then(resolve, reject);
          },
          (e) => {
            console.error("Pas de coordonnées, on sauvegarde quand même", e);
            this.doSendTripAndCancelCreations(trip).then(resolve, reject);
          }
        );
      });
    } else {
      return this.doSendTripAndCancelCreations(trip);
    }
  }

  static syncTrips(): Promise<boolean> {
    const result: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
      let someTripsSaved: boolean = false;
      const allPromises: Promise<void>[] = [];

      this.getDatabase().dirtyTrips.toArray((dirtyTrips) => {
        if (dirtyTrips && dirtyTrips.length > 0) {
          console.info(`${dirtyTrips.length} sorties à synchroniser`);
          dirtyTrips.forEach((dirtyTrip: TripBean) => {
            if (this.shouldDelaySave(dirtyTrip)) {
              console.debug(
                `On ignore la sortie qui est peut-être encore en cours de modif`,
                dirtyTrip.id
              );
            } else {
              const promise = this.syncTrip(dirtyTrip);
              promise.then(
                () => {
                  this.getDatabase().dirtyTrips.delete(dirtyTrip.id!);
                  someTripsSaved = true;
                  console.debug("Trip saved !");
                },
                (error) => {
                  console.error("Unable to sync trip ", error);
                }
              );
              allPromises.push(promise);
            }
          });

          Promise.all(allPromises).then(() => {
            resolve(someTripsSaved);
          }, reject);
        } else {
          console.info("Aucune sortie à synchroniser");
          resolve(false);
        }
      });
    });

    return result;
  }

  static hasOtherSpecies(trip: TripBean): boolean {
    if (trip.otherSpecies) {
      return true;
    }
    if (trip.catchs && trip.catchs.length > 0) {
      for (let i: number = 0; i < trip.catchs.length; i++) {
        const someCatch = trip.catchs[i];
        if (someCatch.otherSpecies) {
          return true;
        }
      }
    }

    return false;
  }

  static syncTrip(trip: TripBean): Promise<void> {
    return new Promise((resolve, reject) => {
      console.debug("On essaye de sauvegarder la sortie", trip);
      if (trip.createdOn) {
        this.backendPut(`/v1/trips/${trip.id}`, trip).then((r) => {
          PicturesService.checkForPicturesToRename(r);
          if (this.hasOtherSpecies(trip)) {
            ReferentialService.clearSpeciesCustomCache();
          }
          resolve();
        }, reject);
      } else {
        this.backendPost("/v1/trips", trip).then((r) => {
          PicturesService.checkForPicturesToRename(r);
          if (this.hasOtherSpecies(trip)) {
            ReferentialService.clearSpeciesCustomCache();
          }
          resolve();
        }, reject);
      }
    });
  }

  static getLatestCatchSpecies(someTrip: TripBean): string | undefined {
    if (someTrip.catchs && someTrip.catchs.length > 0) {
      const latestCatch = someTrip.catchs[someTrip.catchs.length - 1];
      return latestCatch.speciesId;
    }
    return;
  }

  static getLatestCatchTechniques(someTrip: TripBean): string | undefined {
    if (someTrip.catchs && someTrip.catchs.length > 0) {
      const latestCatch = someTrip.catchs[someTrip.catchs.length - 1];
      return latestCatch.techniqueId;
    }
    return;
  }

  static getTripAndCatch(
    tripId: any,
    catchId: any,
    callback: (t: TripBean, c: CatchSummary) => void
  ) {
    TripsService.getTrip(tripId, (trip: TripBean) => {
      let result: CatchSummary = { id: catchId };
      if (trip.catchs) {
        trip.catchs.forEach((someCatch: CatchBean) => {
          if (catchId == someCatch.id) {
            result = someCatch;
          }
        });
      }
      if (result.id == Constants.NEW_CATCH_ID) {
        if (trip.mode == "Live") {
          result.caughtAt = moment().format(moment.HTML5_FMT.TIME_SECONDS);
        }

        // On essaye de récupérer la dernière espèce capturée pour sélectionner la même
        const latestSpeciesId = this.getLatestCatchSpecies(trip);
        if (latestSpeciesId) {
          result.speciesId = latestSpeciesId;
        }

        // On essaye de récupérer la dernière technique utilisée pour sélectionner la même
        const latestTechniqueId = this.getLatestCatchTechniques(trip);
        if (latestTechniqueId) {
          result.techniqueId = latestTechniqueId;
        }
      }
      callback(trip, result);
    });
  }

  static saveCatch(
    tripId: any,
    aCatch: CatchBean,
    callback: (catchId: string) => void
  ) {
    TripsService.getTrip(tripId, (trip: TripBean) => {
      if (!trip.catchs) {
        trip.catchs = [];
      }
      let found = false;
      for (let i: number = 0; i < trip.catchs.length; i++) {
        const someCatch = trip.catchs[i];
        if (aCatch.id == someCatch.id) {
          found = true;
          trip.catchs[i] = aCatch;
        }
      }
      if (!found) {
        if (aCatch.id == Constants.NEW_CATCH_ID) {
          aCatch.id = new Date().getTime().toString();
        }
        trip.catchs.push(aCatch);
      }
      TripsService.saveTrip(trip, () => {
        callback(aCatch.id);
      });
    });
  }

  static deleteCatch(tripId: any, catchId: any, callback: () => void) {
    PicturesService.deleteAllPicturesForCatch(catchId);
    TripsService.getTrip(tripId, (trip: TripBean) => {
      if (!trip.catchs) {
        trip.catchs = [];
      }
      let foundAtIndex: number = -1;
      for (let i: number = 0; i < trip.catchs.length; i++) {
        const someCatch = trip.catchs[i];
        if (catchId == someCatch.id) {
          foundAtIndex = i;
        }
      }
      if (foundAtIndex >= 0) {
        trip.catchs.splice(foundAtIndex, 1);
      }
      TripsService.saveTrip(trip, () => {
        callback();
      });
    });
  }

  static deleteTrip(tripId: any, callback: () => void) {
    this.getDatabase().dirtyTrips.delete(tripId);
    this.backendDelete(`/v1/trips/${tripId}`).then(callback);
  }

  static deleteTrips(tripIds: string[]): Promise<void> {
    console.info("Supprime les sorties", tripIds);
    return this.backendDelete(`/v1/trips`, tripIds);
  }

  static getRunningTrip(): Promise<TripMain> {
    return new Promise<TripMain>((resolve, reject) => {
      this.getDatabase()
        .onCreationTrip.get(Constants.RUNNING_ID)
        .then((runningTrip) => {
          if (runningTrip) {
            if (runningTrip.mode == "Live") {
              const dateMoment = moment(runningTrip.date);
              const todayMoment = moment();
              if (dateMoment.dayOfYear() == todayMoment.dayOfYear()) {
                resolve(runningTrip);
              } else {
                console.debug(
                  "Il y avait une sortie live en cours, on la bascule en sortie à posteriori"
                );
                runningTrip.mode = "Afterwards";
                this.saveTrip(runningTrip, () => {
                  resolve(runningTrip);
                });
              }
            } else {
              resolve(runningTrip);
            }
          } else {
            reject("Pas de running trip");
          }
        });
    });
  }

  static hasRunningTrip(): Promise<boolean> {
    return new Promise<boolean>((resolve, _reject) => {
      this.getRunningTrip().then(
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  static newSampleId(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      ProfileService.getProfile().then((profile) => {
        const now = moment();
        // TODO AThimel 01/04/2020 Pour l'instant on garanti l'unicité sur la base de :
        // TODO AThimel 01/04/2020   [jour-du-mois] * 1440 + [heure] * 60 + [minute]
        // TODO AThimel 01/04/2020 Quand on saura en assurer l'unicité, utiliser une séquence propre au [sampleBaseId]
        let number = now.date() * (24 * 60) + now.hours() * 60 + now.minutes();
        if (localStorage) {
          if (localStorage.highestSampleId >= number) {
            number = parseInt(localStorage.highestSampleId) + 1;
          }
          localStorage.highestSampleId = number;
        }
        const result = profile.sampleBaseId + "-" + number;
        resolve(result);
      }, reject);
    });
  }
}
