import { render, staticRenderFns } from "./FishingLicenceFullScreen.vue?vue&type=template&id=6ec0bff3&scoped=true"
import script from "./FishingLicenceFullScreen.vue?vue&type=script&lang=ts"
export * from "./FishingLicenceFullScreen.vue?vue&type=script&lang=ts"
import style0 from "./FishingLicenceFullScreen.vue?vue&type=style&index=0&id=6ec0bff3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec0bff3",
  null
  
)

export default component.exports